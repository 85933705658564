import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import tw from "twin.macro"

const ProjectWrapper = tw.div`flex flex-col justify-center mb-16 sm:mb-32`
const Title = tw.h1`text-2xl sm:text-4xl font-extrabold font-display mt-24`
const ProjectsContainer = tw.div` flex flex-wrap justify-center items-center content-start mt-8`
const GatsbyImage = tw(Img)`rounded-lg shadow-lg h-56 w-56 m-1`

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      philips: file(relativePath: { eq: "Philips.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ems: file(relativePath: { eq: "Expo-logo-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shire: file(relativePath: { eq: "Shire.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      waiterly: file(relativePath: { eq: "waiterly.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      viva: file(relativePath: { eq: "viva_las_trivia.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ProjectWrapper id="projects">
      <Title>Recent work</Title>
      <ProjectsContainer>
        <a
          target="_blank"
          href="https://www.figma.com/proto/f1YCnbpRhFzuVCfwfl8sos/PHILIPS-Service-Order-Solutions?node-id=12%3A1&scaling=contain"
        >
          <GatsbyImage
            alt="This is a image of a Philips landing page designed by Jake Preston"
            fluid={data.philips.childImageSharp.fluid}
          />
        </a>
        <a
          target="_blank"
          href="https://www.eventscribe.com/2020/EMSWorldExpo/agenda.asp?pfp=FullSchedule"
        >
          <GatsbyImage
            alt="EMS World Expo Logo"
            fluid={data.ems.childImageSharp.fluid}
          />
        </a>
        <a
          target="_blank"
          href="https://github.com/Preston-Jake/Viva-Las-Trivia"
        >
          <GatsbyImage
            alt="Viva Las Trivia created by Jake Preston"
            fluid={data.viva.childImageSharp.fluid}
          />
        </a>
        <a target="_blank" href="https://github.com/Preston-Jake/Urban-Shire">
          <GatsbyImage
            alt="Shire — Jake Preston's frontend capstone at Nashville Software School"
            fluid={data.shire.childImageSharp.fluid}
          />
        </a>
        <a target="_blank" href="https://github.com/Preston-Jake/Waiterly">
          <GatsbyImage
            alt="Waiterly — Jake Preston's backend capstone at Nashville Software School"
            fluid={data.waiterly.childImageSharp.fluid}
          />
        </a>
      </ProjectsContainer>
    </ProjectWrapper>
  )
}

export default Projects
