import React from "react"
import tw from "twin.macro"

const AboutContainer = tw.div`mb-16 sm:mb-32 flex flex-col justify-center`
const ArticleContainer = tw.div``
const Title = tw.div`text-2xl sm:text-4xl font-extrabold font-display`
const Paragraph = tw.p`text-base sm:text-2xl mt-2 font-body`

const About = () => {
  return (
    <AboutContainer id="profile">
      <ArticleContainer>
      <Title>Why software</Title>
      <Paragraph>
        Software Development is a way for me to combine logic with
        creativity and work in an industry with endless learning opportunities.
      </Paragraph>
      </ArticleContainer>
    </AboutContainer>
  )
}

export default About
