import React from "react"
import tw from "twin.macro"

const ExperienceContainer = tw.div`flex flex-col justify-center mb-16 sm:mb-32`
const Title = tw.h3`text-2xl sm:text-4xl font-extrabold font-display`
const Content = tw.div``
const P = tw.p`mt-4 sm:mt-8 font-body text-lg sm:text-2xl`
const Ul = tw.ul`mt-4 ml-6`
const Li = tw.li`list-disc font-body sm:text-lg `

const Experience = () => {
  return (
    <ExperienceContainer id="resume">
      <Title>Professional Experience</Title>
      <Content>
        <P>
          Full-Stack Developer — Nashville Software School — April 2019 -
          October 2019
        </P>
        <P>
          Intensive full-time 6-month software development Bootcamp anchoring
          object-oriented programming fundamentals with full-stack development
          in a simulated working environment
        </P>
        <Ul>
          <Li>
            Languages and Technologies: HTML | CSS | JavaScript | React.js | C#
            | .NET
          </Li>
          <Li>
            Immersed in a curriculum that covers both frontend and backend web
            development.
          </Li>
          <Li>
            Cultivated communication skills in an Agile working environment to
            boost performance among team-mates.
          </Li>
          <Li>
            Maintained active leadership during group projects improving peers'
            knowledge and understanding of course material.
          </Li>
          <Li>
            Developed fundamental skills to operate as a full-stack developer.
          </Li>
        </Ul>
      </Content>
      <Content>
        <P>
          Freelance Infographic Designer — October 2018 - January 2019 October
          2019
        </P>
        <P>
          Tennessee Medical Trade Association Association with the intent on
          changing Tennessee Law, mitigating the Opioid Crisis, and opening
          doors into new Medical and Agricultural markets
        </P>
        <Ul>
          <Li>Technologies: Google Suite | Prezi | Canva</Li>
          <Li>
            Contributed literature on Bills presented to the TN General
            Assembly.
          </Li>
          <Li>
            Designed infographics using polling and field data to support the
            Association's argument for new laws.
          </Li>
          <Li>
            Strived for the greater good of Tennessee residents and to reverse
            the effects of the National Opioid Crisis.
          </Li>
        </Ul>
      </Content>
    </ExperienceContainer>
  )
}

export default Experience
