import React from "react"

//components
import Layout from "../components/layout"
import Home from "../components/home"
import Projects from "../components/projects"
import About from "../components/about"
import Education from "../components/education"
import Experience from "../components/experience"
import Capabilites from "../components/capabilities"

const IndexPage = () => {

  return (
    <Layout>
      <Home />
      <Projects />
      <About/>
      <Capabilites/>
      <Experience />
      <Education />
    </Layout>
  )
}

export default IndexPage
