import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import Img from "gatsby-image"

const Section = tw.div`mb-16 sm:mb-32`
const ImageContainer = tw.div`flex justify-around`
const GatsbyImage = tw(Img)`w-24 sm:w-24`
const Subtitle = tw.h3`text-lg sm:text-2xl mt-2 font-body font-bold`

const Education = () => {
  const data = useStaticQuery(graphql`
    query {
      NSS: file(relativePath: { eq: "NSS.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FRA: file(relativePath: { eq: "FRA.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section>
    <Subtitle>Education</Subtitle>
    <ImageContainer>
      <GatsbyImage alt="" fluid={data.NSS.childImageSharp.fluid} />
      <GatsbyImage alt="" fluid={data.FRA.childImageSharp.fluid} />
    </ImageContainer>
    </Section>
  )
}

export default Education



