import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import Img from "gatsby-image"

const Section = tw.div` flex flex-col justify-center mb-16 sm:mb-32`
const ImageContainer = tw.div`flex justify-around mt-4 sm:mt-8`
const GatsbyImage = tw(Img)`w-8 sm:w-16`

const Capabilites = () => {
  const data = useStaticQuery(graphql`
    query {
      html: file(relativePath: { eq: "html.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      css: file(relativePath: { eq: "css.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      js: file(relativePath: { eq: "js.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      c: file(relativePath: { eq: "c.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ruby: file(relativePath: { eq: "Ruby.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      python: file(relativePath: { eq: "Python.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      NSS: file(relativePath: { eq: "NSS.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FRA: file(relativePath: { eq: "FRA.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section id="capabilites">
      <ImageContainer>
        <GatsbyImage alt="" fluid={data.html.childImageSharp.fluid} />
        <GatsbyImage alt="" fluid={data.css.childImageSharp.fluid} />
        <GatsbyImage alt="" fluid={data.js.childImageSharp.fluid} />
        <GatsbyImage alt="" fluid={data.c.childImageSharp.fluid} />
        <GatsbyImage alt="" fluid={data.ruby.childImageSharp.fluid} />
        <GatsbyImage alt="" fluid={data.python.childImageSharp.fluid} />
      </ImageContainer>
    </Section>
  )
}

export default Capabilites
